.wallets {
  width: 100%;
  height: 100%;
  padding: 128px 0 328px 0;
  position: relative;
}
.wallets__body {
  position: relative;
}
.wallets__title {
  font-family: "Space Mono";
  font-style: normal;
  font-size: 17.555px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  /* font-weight: 700; */
  /* font-size: 48px; */
  /* line-height: 56px; */
  color: #FFFFFF;
  /* margin-bottom: 24px; */
}
.wallets__list {
  display: flex;
  flex-wrap: wrap;
  z-index: 10;
  /* gap:14px; */
  position: relative;
  justify-content: flex-start;
  margin-left: -14px;
  margin-right: -14px;
}
.wallets__item {
  position: relative;
  padding: 14px;
  width: 25%;
  /* flex-basis: 25%; */

  /* flex: 0 1 24%; */
  /* display: flex; */
  /* display: flex;
  padding: 24px 48px 24px 24px;
  flex: 0 1 24%;
  border-radius: 16px;
  background: #29292E; */
  /* margin-bottom: 10px; */
}
.wallets__item-inner{
  position: relative;
  display: flex;
  padding: 23px 30px 30px 19px;
  /* padding: 24px 48px 24px 24px; */
  /* flex: 0 1 24%; */
  border-radius: 16px;
  background: #FFF;
  height: 100%;
}
/* .wallets__item
:not(:nth-child(5n+5), :first-child) {
  margin-left: 6px;
  margin-right: 6px;
} */


.wallets__item-inner::before {
  content: "";
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px;
  /* background: radial-gradient(80.69% 208.78% at 108.28% 112.58%, #FFFFFF 0%, rgba(226, 226, 226, 0) 100%); */
  background: linear-gradient(#359CFB, #FFFFFF);
  border-radius: 16px;
  z-index: -1;
}

/* .wallets__item::before {
  content: "";
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px;
  background: radial-gradient(80.69% 208.78% at 108.28% 112.58%, #FFFFFF 0%, rgba(226, 226, 226, 0) 100%);
  background: linear-gradient(#359CFB, #FFFFFF);
  border-radius: 16px;
  z-index: -1;
} */
.wallets__item-block {
  display: flex;
  flex-direction: column;
}
.wallets__item-title {
  word-break: break-all;
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 400;
  /* font-weight: 700; */
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 14px;
  /* color: #FFFFFF; */
}



.wallets__item-description {
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #828282;
  overflow-wrap: anywhere;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wallets__image {
  display: block;
  margin-right: 20px;
  object-fit: contain;
}

.wallets__item-social {
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #828282;
  display: flex;
  flex-flow: row;
  grid-gap: 10px;
  padding-top: 10px;
  mix-blend-mode: exclusion;
}

@media screen and (min-width: 300px) and (max-width: 780px) {
  .wallets__list {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .wallets__item {
    width: 100%;
    margin-bottom: 15px;
  }
  .vertical__text {
    display: none;
  }
  .gradient_rectangle_line {
    display: none;
  }
}
@media screen and (min-width: 781px) and (max-width: 1250px) {
  .wallets__list {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .wallets__item {
    width: 50%;
    flex-basis: 50%;
    /* flex: 0 1 48%; */
  }
  .vertical__text {
    display: none;
  }
}
@media screen and (min-width: 2500px) {
  .wallets {
    padding-top: 200px;
  }
  .wallets__title {
    font-size: 63px;
  }
  .wallets__list {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .wallets__item-title {
    font-size: 32px;
    line-height: 44px;
  }
  .wallets__item-description {
    font-size: 24px;
    line-height: 42px;
  }
}

.gradient_rectangle_line {
  width: 25%;
  /* background: linear-gradient(264.23deg, #00C4B8 4.42%, #0ACE88 98.24%); */
  background: linear-gradient(264.23deg, rgba(53, 156, 251, 1) 4.42%, rgba(53, 156, 251, 0.75) 98.24%)!important;
  opacity: 0.8;
  position: fixed;
  height: 100vh!important;
  top: 0;
  right: 0;
}