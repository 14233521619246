@import url('https://fonts.googleapis.com/css2?family=Tomorrow:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Saira+Semi+Condensed:wght@700');
.container {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.banner_title_custom {
  font-family: 'Tomorrow';
  font-weight: 400;
}

.banner_title_h3{
  font-family: "Space Mono";
  margin-top: 25px;
  font-size: 17.555px;
  text-transform: uppercase;
  width: 340px;
  font-weight: 400;
  max-width: 100%;
}

._pc {
  display: block !important;
}

._mob {
  display: none !important;
}

.green {
  color: #359cfb;
}

.dark_rectangle {
  display: block;
  position: absolute;
  background: #29292E;
}

.dark_rectangle-top {
  left: 0;
}

.dark_rectangle-bottom {
  left: 0;
  top: 100%;
}

.gradient_rectangle {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;

  background: linear-gradient(264.23deg, #00C4B8 4.42%, #0ACE88 98.24%);
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
}

.main__body{
  position: relative;
  z-index: 2;
  pointer-events: none;
}

.gradient_rectangle-img {
  display: block;
  position: absolute;
}

.gradient_rectangle-img:first-child {
  top: 10%;
  right: 20%;
  background: url("../../assets/img/ellips_1.png") center no-repeat;
  background-size: 100%;
}

.gradient_rectangle-img:last-child {
  bottom: 10%;
  right: 10%;
  background: url("../../assets/img/ellips_2.png") center no-repeat;
  background-size: 100%;
}


/* .wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
} */

.main {
  min-height: 100%;
  /* background: url("../../assets/img/main_page_bg.jpg") center no-repeat; */
  background-size: cover;
}

.home-page_container,
.home-page_container .main__body{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /* align-items: center; */
}

.main__body {
  padding: 121px 0 60px 0;
  /* max-width: 795px; */
  max-width: 55%;
}

.main__canvas{
  position: fixed;
  top: 50%;
  right: 80px;
  width: 70%;
  height: 100%;
  transform: translateY(-50%);
  z-index: 0;
}

.main__title {
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  font-size: 7.4vmax;
  line-height: 7vmax;
}

.main__title-dark {
  display: block;
  margin-bottom: 32px;
  color: #29292E;
}

.main__title-white {
  color: #FFFFFF;
}

.main__subtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #fff;
  margin-top: 60px;
  line-height: 53px;
  padding: 8px 0;
  text-align: center;
  background: rgba(41, 41, 46, 0.6);
}

.main__subtitle-logo {
  display: block;
  position: relative;
}

.main__subtitle-logo::after {
  position: absolute;
  content: none;
  display: block;
  width: 52px;
  height: 52px;
  background: url("../../assets/img/icon_moon.png");
  top: 0;
  right: 0;
  transform: translateX(62px);
}

.main__btns {
  margin-top: 60px;
  display: flex;
}


.main__btn {
  pointer-events: all;
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding: 15px 30px;
  font-size: 18px;
  /* padding: 6px 24px; */
  background: transparent;
  /* background: #4D4E58; */
  /* border: 2px solid #FFFFFF!important; */
  color: #fff;
  border-radius: 100px;
}

.border-around{
  border: 2px solid #FFFFFF!important;
}

.main__btn:not(:last-child) {
  margin-right: 12px;
}

.main__btn {
  /* background: #484953; */
  /* background: #359cfb; */
}

.main__btn:hover {
  background: rgba(255, 255, 255,0.3);
}

.main__btn::before {
  font-size: 40px;
  color: #fff;
  opacity: 1;
  margin-right: 10px;
}

@media screen and (min-width: 300px) and (max-width: 780px) {
  .main__canvas{
    right: 0px;
  }
  .dark_rectangle {
    display: none;
  }
  .home-page_container,
  .home-page_container .main__body{
    min-height: 80vh;

  }

  .main {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    /* background: linear-gradient(264.23deg, #00C4B8 4.42%, #0ACE88 98.24%); */
    padding: 125px 0 0 0;
  }

  .main__btn{
    text-align: center!important;
    width: 100%;
    justify-content: center;
  }

  .main__body {
    max-width: 100%;
    padding: 0;
  }

  .main__title {
    font-size: 63px;
    line-height: 59px;
    width: 60%;
  }

  .main__title-dark {
    margin-bottom: 0;
  }

  .main__subtitle {
    font-size: 18px;
  }

  .main__subtitle-logo::after {
    top: 0;
    width: 40px;
    height: 40px;
    background-size: cover;
    background-position: center;
    transform: translate(45px, 20%);
  }

  .main__btns {
    flex-direction: column;
  }

  .main__link {
    margin-right: 120px !important;
  }

  .main__btn {
    margin-bottom: 10px;
    width: 100%;
  }
}

@media screen and (min-width: 781px) and (max-width: 1250px) {
  .main__canvas{
    right: 0px;
  }
  .dark_rectangle {
    display: none;
  }

  .main__title {
    width: 50%;
    font-size: 90px;
    line-height: 80px;
  }

  .main__title-dark {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 2500px) {
  .main__body {
    max-width: 804px;
  }

  .main__btn {
    font-size: 24px;
    padding: 10px 20px;
  }

  .main__subtitle {
    font-size: 29px;
  }

  .main__subtitle-logo::after {
    width: 70px;
    height: 70px;
    background-size: cover;
    top: 0;
    transform: translate(82px, -7%);
  }
}