
.viewport-scroll-text-wrapper-vertical {
    pointer-events: none;
    position: fixed;
    left: 0%;
    top: 0%;
    right: auto;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 100px;
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    /* background-color: #000; */
  }
  
  .viewport-scroll-text-wrapper-vertical.right {
    left: auto;
    top: 0%;
    right: 0%;
    bottom: 0%;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  
  .viewport-scroll-text-list-vertical {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    /* height: 100vh; */
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  
  .viewport-scroll-text-wrapper-vertical .list-item {
    /* min-height: 600px; */
  
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
    /* min-height: 30%; */
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    font-size: 100px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  
    font-family: Tomorrow;
    font-size: 100px;
    font-style: normal;
    font-weight: 400;
    line-height: 29.391px;
    letter-spacing: 6px;
  }
  
  .viewport-scroll-text-wrapper-vertical .list-item:nth-child(1){
    min-height: 331px;
  }
  .viewport-scroll-text-wrapper-vertical .list-item:nth-child(2){
    min-height: 632px;
  }
  .viewport-scroll-text-wrapper-vertical .list-item:nth-child(3){
    min-height: 337px;
  }
  
  .viewport-scroll-text-list-item-vertical {
    width: 32px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  
  .viewport-scroll-text-list-item-text-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 32px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .viewport-scroll-text-wrapper-vertical .text-block {
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    color: #fff;
  }
  
  ul.viewport-scroll-text-list-vertical.cloned {
  display: -webkit-box;
    position:absolute;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  
  ul.viewport-scroll-text-list-vertical{
    animation: roll 20s linear infinite;
  }
  
  @keyframes roll {
      0%{
        transform: translateY(0%);
      }
      100%{
        transform: translateY(100%)
      }
  }
  
  @media screen and (max-width: 1250px){
    .viewport-scroll-text-wrapper-vertical{
      display: none!important;
    }
  }